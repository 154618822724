import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import PageContent from '../components/layouts/PageContent';
import {
    MetricsClient,
    ConnectQueueMetrics,
    ConnectQueues
} from '../util/MetricsClient';
import { MetricsSelector } from '../components/metrics/MetricsSelector';
import settings from '../settings.json';
import { Button, Card, Collapse, Empty, Spin, Table } from 'antd';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { VictoryPie } from 'victory';
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai-location';
const { Panel } = Collapse;

const instanceIdAtom = atomWithHash('instanceId', '');
const channelAtom = atomWithHash('channel', '');
const queueIdAtom = atomWithHash('queueId', '');
const tvAtom = atomWithHash('tv', false);
const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefix;

import { useContinuousAuth } from './App';

export function MetricsDashboardPage() {
    const [tv, setTv] = useAtom(tvAtom);

    return (
        <PageContent
            authenticated={true}
            expanded={tv}
            footer={true}
        >
            <AuthedMetricsDasboard />
        </PageContent>
    );
}

const REALTIME_METRICS = [
    'AGENTS_AFTER_CONTACT_WORK',
    'AGENTS_AVAILABLE',
    'AGENTS_ERROR',
    'AGENTS_NON_PRODUCTIVE',
    'AGENTS_ONLINE',
    'AGENTS_ON_CONTACT',
    'CONTACTS_IN_QUEUE',
    'OLDEST_CONTACT_AGE',
    'SLOTS_ACTIVE',
    'SLOTS_AVAILABLE',
];

const HISTORICAL_METRICS = [
    'HANDLE_TIME',
    'SERVICE_LEVEL_LT_60',
];

const getTimePosition = (secs: number, position: number) =>
    String(Math.floor(secs / Math.pow(60, position)) % 60).padStart(2, '0');

const getHhMmSs = (secs: number) =>
    `${getTimePosition(secs, 2)}:${getTimePosition(secs, 1)}:${getTimePosition(secs, 0)}`;

const getMmSs = (secs: number) =>
    `${getTimePosition(secs, 1)}:${getTimePosition(secs, 0)}`;


const MetricsWrapper = styled.div`
`;

const MetricsContent = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 250px;
`;

const MetricCards = styled.div`
    flex: 1 1 1px;
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    justify-content: space-between;
`;

const MetricsCharts = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
`;


const MetricsLegend = styled.div`
    flex: 1 1 1px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    
    @media (max-width: 640px) {
        margin-bottom: 20px;
    }
`;

const MetricPie = styled.div`
    flex: 1 1 1px;
    min-width: 300px;
`;

const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
`;

const CardTitle = styled.div`
    font-family: equipLight;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;

    margin-top: 5px;
`;

const CardSubtitle = styled.div`
    --bs-text-opacity: 1;
    color: #6c757d !important;
    font-family: equipLight;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;

    margin-bottom: 5px;
`;

const Tile = styled.div<{
    larger?: boolean;
}>`
    display: flex;
    flex: 1; 
    flex-direction: column;
    margin: 0px 10px 10px;

    &:last-child {
        margin-right: 0px; // Remove margin for the last Tile
        ${props => props.larger && css`
            margin-right: 20px;
        `}
    }
`;

const TileMetricContent = styled.div<{
    larger?: boolean;
}>`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & > p {
        text-align: center;
        margin: 0px;

        ${props => props.larger && css`
            font-size: 24px;
        `}
    }

    & > p.lg {
        text-align: center;
        font-size: 46px;
        font-weight: bold;
        margin: 0px;

        ${props => props.larger && css`
            font-size: 65px;
        `}
    }

    & > p.secondary {
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        margin: 0px;

        ${props => props.larger && css`
            font-size: 65px;
        `}
    }
`;

const ColorIndicator = styled.div<{
    color?: string;
    larger?: boolean;
}>`
    width: 25px;
    height: 25px;
    background-color: ${props => props.color};
    border-radius: 100px;

    ${props => props.larger && css`
        width: 40px;
        height: 40px;
    `}
`;

const TableKey = styled.span<{
    larger?: boolean;
}>`
    font-weight: 400;
    font-size: 24px;

    ${props => props.larger && css`
        font-size: 40px;
    `}
`;
const TableValue = styled.span<{
    larger?: boolean;
}>`
    font-weight: 700;
    font-size: 24px;

    ${props => props.larger && css`
        font-size: 40px;
    `}
`;

const NoData = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 375px;
    text-align: center;
    color: #6c757d;
    font-weight: 400;
    font-size: 18px;
`;

const useQueues = (instanceId: string): ConnectQueues => {
    const { getHeaders } = useContinuousAuth();
    const metricsClient = new MetricsClient(apiPrefix, getHeaders);
    const [queues, setQueues] = useState<ConnectQueues>({});

    // load queues
    useEffect(() => {
        if (instanceId) {
            metricsClient.listQueues(instanceId).then(setQueues);
        }
    }, [instanceId]);

    return queues;
};

function AuthedMetricsDasboard() {

    const [loading, setLoading] = useState(true);

    const [instanceId, setInstanceId] = useAtom(instanceIdAtom);
    const [channel, setChannel] = useAtom(channelAtom);
    const [queueId, setQueueId] = useAtom(queueIdAtom);
    const [tv, setTv] = useAtom(tvAtom);

    const [realTime, setRealTime] = useState<ConnectQueueMetrics>({});
    const [hist30min, setHist30min] = useState<ConnectQueueMetrics>({});
    const [hist24hr, setHist24Hr] = useState<ConnectQueueMetrics>({});

    const [queueWithOldestContact, setQueueWithOldestContact] = useState('');

    const queues = useQueues(instanceId);
    const { getHeaders } = useContinuousAuth();
    const metricsClient = new MetricsClient(apiPrefix, getHeaders);

    useEffect(() => {
        setRealTime({});
        setHist30min({});
        setHist24Hr({});
        setQueueWithOldestContact('');

        const allQueueIds = Object.keys(queues).join(',');

        const intervalNow = 0;
        const interval30min = (30 * 60);
        const interval24hr = (24 * 60 * 60) - (5 * 60);

        if (instanceId && channel && allQueueIds) {
            const refreshMetrics = () => {
                setLoading(true);

                const getRealTime = metricsClient.getMetrics(instanceId, channel, allQueueIds, intervalNow, REALTIME_METRICS.join(','));
                const getHist30min = metricsClient.getMetrics(instanceId, channel, queueId, interval30min, HISTORICAL_METRICS.join(','));
                const getHist24hr = metricsClient.getMetrics(instanceId, channel, queueId, interval24hr, HISTORICAL_METRICS.join(','));

                Promise.all([getRealTime, getHist30min, getHist24hr])
                    .then(results => {
                        const realTimeMetrics = results[0];
                        let aggregatedMetrics: ConnectQueueMetrics = {};

                        if (queueId === '') { // Empty QueueId string '' represents 'All Queues'
                            for (let currQueueId in realTimeMetrics) {
                                for (let metric in realTimeMetrics[currQueueId]) {
                                    aggregatedMetrics[metric] = (aggregatedMetrics[metric] || 0) + realTimeMetrics[currQueueId][metric];
                                }
                            }
                        } else {
                            aggregatedMetrics = realTimeMetrics[queueId] || {};
                        }

                        setRealTime(aggregatedMetrics);
                        setHist30min(results[1][queueId] || results[1]);
                        setHist24Hr(results[2][queueId] || results[2]);

                        let oldestContactInQueue = undefined;
                        for (let [queueId, { name }] of Object.entries(queues)) {
                            const age = results[0][queueId]?.OLDEST_CONTACT_AGE;

                            if (age && age > (oldestContactInQueue?.age || 0)) {
                                oldestContactInQueue = { age, name };
                            }
                        }

                        setQueueWithOldestContact(oldestContactInQueue?.name || '');
                    })
                    .finally(() => setLoading(false));
            };

            refreshMetrics();
            const intervalId = setInterval(refreshMetrics, 3 * 60 * 1000);
            return () => clearInterval(intervalId);
        }
    }, [instanceId, channel, queues, queueId]);

    const agentsAcw = useMemo(() => realTime.AGENTS_AFTER_CONTACT_WORK, [realTime]);
    const agentsAvailable = useMemo(() => realTime.AGENTS_AVAILABLE, [realTime]);
    const agentsError = useMemo(() => realTime.AGENTS_ERROR, [realTime]);
    const agentsNpt = useMemo(() => realTime.AGENTS_NON_PRODUCTIVE, [realTime]);
    const agentsOnContact = useMemo(() => realTime.AGENTS_ON_CONTACT, [realTime]);
    const contactsInQueue = useMemo(() => realTime.CONTACTS_IN_QUEUE, [realTime]);
    const oldestContactAge = useMemo(() => realTime.OLDEST_CONTACT_AGE, [realTime]);
    const hist30minHandleTime = useMemo(() => hist30min.HANDLE_TIME, [hist30min]);
    const hist30minServiceLevelLt60 = useMemo(() => hist30min.SERVICE_LEVEL_LT_60, [hist30min]);
    const hist24hrServiceLevelLt60 = useMemo(() => hist24hr.SERVICE_LEVEL_LT_60, [hist24hr]);

    // Pie Chart
    const data = [
        { x: 'On Contact', y: agentsOnContact },
        { x: 'NPT', y: agentsNpt },
        { x: 'ACW', y: agentsAcw },
        { x: 'Error', y: agentsError },
        { x: 'Available', y: agentsAvailable }
    ];
    const colorScale = ['#1ABC9C', '#F1C40F', '#9B59B6', '#E74C3C', '#3498DB'];
    // Check if Pie Chart is empty
    const emptyChart = data.every(datum => datum.y === 0 || datum.y === undefined);

    // Legend Table Data
    const chartData = [
        { key: '1', title: 'On Contact', value: agentsOnContact, color: '#1ABC9C' },
        { key: '2', title: 'NPT', value: agentsNpt, color: '#F1C40F' },
        { key: '3', title: 'ACW', value: agentsAcw, color: '#9B59B6' },
        { key: '4', title: 'Error', value: agentsError, color: '#E74C3C' },
        { key: '5', title: 'Available', value: agentsAvailable, color: '#3498DB' },
    ];
    // Legend Table Columns
    const columns = [
        {
            title: '',
            dataIndex: 'color',
            key: 'key',
            width: '30px',
            render(value: string | undefined, _record: any, _index: any) {
                return (
                    <ColorIndicator color={value} larger={tv} />
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'title',
            key: 'key',
            render(value: string | undefined, _record: any, _index: any) {
                return (
                    <TableKey larger={tv}>
                        {value}
                    </TableKey>
                );
            }
        },
        {
            title: 'Count',
            dataIndex: 'value',
            key: 'key',
            render(value: string | undefined, _record: any, _index: any) {
                return (
                    <TableValue larger={tv}>
                        {value}
                    </TableValue>
                );
            }
        },
    ];

    const formattedLwt = useMemo(
        () => oldestContactAge ? getHhMmSs(oldestContactAge) : '',
        [oldestContactAge]
    );
    const formattedAht = useMemo(
        () => hist30minHandleTime ? getMmSs(hist30minHandleTime) : '',
        [hist30minHandleTime]
    );

    return (
        <MetricsWrapper>
            {!tv && (
                <Collapse
                    accordion
                    defaultActiveKey='showFilters'
                    size='large'
                    style={{
                        margin: '0px',
                        backgroundColor: '#fff'
                    }}
                >
                    <Panel
                        header='Select a Queue to Begin'
                        key='showFilters'
                    >
                        <MetricsSelector
                            {...{
                                instanceId, channel, queueId,
                                setInstanceId, setChannel, setQueueId,
                                queues,
                            }}
                        />
                    </Panel>
                </Collapse>
            )}
            {loading ? (
                <Card style={{ marginTop: '15px' }}>
                    <LoadingWrapper>
                        <Spin size='large'/>
                    </LoadingWrapper>
                </Card>
            ) : (<>
                <MetricsContent>
                    <MetricCards>
                        <CardRow>
                            <Tile>
                                <Card
                                    title={
                                        <>
                                            <CardTitle>Average Handle Time</CardTitle>
                                            <CardSubtitle className='mb-2 text-muted'>mm : ss</CardSubtitle>
                                        </>
                                    }
                                    style={{
                                        marginLeft: tv ? '10px' : '-10px',
                                        height: '-webkit-fill-available'
                                    }}
                                >
                                    <TileMetricContent larger={tv}>
                                        <p className='lg'>{formattedAht || 'N/A'}</p>
                                    </TileMetricContent>
                                </Card>
                            </Tile>
                            <Tile>
                                <Card
                                    title={
                                        <>
                                            <CardTitle>Longest Wait Time</CardTitle>
                                            <CardSubtitle className='mb-2 text-muted'>hh : mm : ss</CardSubtitle>
                                        </>
                                    }
                                    style={{
                                        marginLeft: tv ? '10px' : '-10px',
                                        height: '-webkit-fill-available'
                                    }}
                                >
                                    <TileMetricContent larger={tv}>
                                        {formattedLwt ? (
                                            <>
                                                <p className='lg'>{formattedLwt}</p>
                                                <p>({queueWithOldestContact})</p>
                                            </>
                                        ) : (
                                            <p className='lg'>N/A</p>
                                        )}
                                    </TileMetricContent>
                                </Card>
                            </Tile>
                            <Tile>
                                <Card
                                    title={
                                        <>
                                            <CardTitle>Neighbors in Queue</CardTitle>
                                            <CardSubtitle className='mb-2 text-muted'>count</CardSubtitle>
                                        </>
                                    }
                                    style={{
                                        marginLeft: tv ? '10px' : '-10px',
                                        height: '-webkit-fill-available'
                                    }}
                                >
                                    <TileMetricContent larger={tv}>
                                        <p className='lg'>{contactsInQueue || 0}</p>
                                    </TileMetricContent>
                                </Card>
                            </Tile>
                            <Tile>
                                <Card
                                    title={
                                        <>
                                            <CardTitle>Service Level</CardTitle>
                                            <CardSubtitle className='mb-2 text-muted'>Past 30 minutes</CardSubtitle>
                                        </>
                                    }
                                    style={{
                                        marginLeft: tv ? '10px' : '-10px',
                                        height: '-webkit-fill-available'
                                    }}
                                >
                                    <TileMetricContent larger={tv}>
                                        <p className='lg'>{hist30minServiceLevelLt60 + '%' || 'N/A'}</p>
                                    </TileMetricContent>
                                </Card>
                            </Tile>
                            <Tile larger={tv}>
                                <Card
                                    title={
                                        <>
                                            <CardTitle>Service Level</CardTitle>
                                            <CardSubtitle className='mb-2 text-muted'>Past 24 hours</CardSubtitle>
                                        </>
                                    }
                                    style={{
                                        marginLeft: tv ? '10px' : '-10px',
                                        height: '-webkit-fill-available'
                                    }}
                                >
                                    <TileMetricContent larger={tv}>
                                        <p className='lg'>{hist24hrServiceLevelLt60 + '%' || 'N/A'}</p>
                                    </TileMetricContent>
                                </Card>
                            </Tile>
                        </CardRow>
                    </MetricCards>
                    <MetricsCharts>
                        <MetricsLegend>
                            <Card
                                title={
                                    <>
                                        <CardTitle>
                                            <CustomerServiceOutlined
                                                style={{
                                                    position: 'absolute',
                                                    left: '40px'
                                                }}
                                            />
                                            Agent Status
                                        </CardTitle>
                                    </>
                                }
                                style={{
                                    marginLeft: tv ? '20px' : '0px',
                                    marginRight: '10px',
                                    height: '-webkit-fill-available'
                                }}
                            >
                                <Table
                                    dataSource={chartData}
                                    columns={columns}
                                    showHeader={false}
                                    pagination={false}
                                />
                            </Card>
                        </MetricsLegend>
                        <MetricPie>
                            <Card
                                style={{
                                    paddingTop: '20px',
                                    marginRight: tv ? '20px' : '0px',
                                    height: '-webkit-fill-available'
                                }}
                            >
                                {emptyChart ?
                                    <NoData>
                                        <Empty description={false} />
                                        No Data
                                    </NoData> :
                                    <VictoryPie
                                        data={data}
                                        labels={['']} // no labels, for labels use commented out lines below
                                        // labels={({datum}) => datum.y}
                                        // labelRadius={({ innerRadius }) => innerRadius + 15}
                                        colorScale={colorScale}
                                        innerRadius={90}
                                        height={300}
                                        style={{
                                            labels: {
                                                fill: '#fff',
                                                fontSize: !tv ? 18 : 24,
                                                fontWeight: 'bold',
                                                fontFamily: 'equiplight',
                                            },
                                        }}
                                        padding={{ bottom: !tv ? 16 : 18 }}
                                    />
                                }
                            </Card>
                        </MetricPie>
                    </MetricsCharts>
                </MetricsContent>
                {!tv && (
                    <Button
                        type='primary'
                        onClick={() => setTv(true)}
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        Switch to TV Display
                    </Button>
                )}
            </>)}
        </MetricsWrapper>
    )
}
