import React from 'react';
import { OktaAuthReact } from '@amzn/ring-cs-okta-auth-client';
import settings from '../settings.json';
import { MetricsDashboardPage } from './MetricsDashboardPage';
import 'antd/dist/reset.css';
import '../components/layouts/font-face.css';

// prep this site to use Okta
const { okta: { iss, cid } } = (settings as any)[window.location.host] || settings.default;
export const {
    ContinuousAuthProvider,
    useContinuousAuth,
    login,
} = new OktaAuthReact(iss, cid);

function App() {
    return (
        <MetricsDashboardPage />
    );
}

export default App;
