import { message } from 'antd';
import axios from 'axios';

export interface OptionType {
    value: string;
    label: string;
    isDisabled?: boolean;
}

export type ConnectChannels = {
    [channel: string]: {
        name: string;
    };
};

export type ConnectInstances = {
    [instanceId: string]: {
        name: string;
    };
};

export type ConnectQueues = {
    [queueId: string]: {
        name: string;
    };
};

export type ConnectQueueMetrics = {
    [metricId: string]: number;
};

export type ConnectMetrics = ConnectQueueMetrics & {
    [queueId: string]: ConnectQueueMetrics;
};

export class MetricsClient {
    private readonly apiPrefix: string;
    private readonly getHeaders: () => Promise<any>;

    constructor(apiPrefix: string, getHeaders: () => Promise<any>) {
        this.apiPrefix = apiPrefix;
        this.getHeaders = getHeaders;
    }

    async listInstances(): Promise<ConnectInstances> {
        try {
            const headers = await this.getHeaders();
            const { data } = await axios.get<ConnectInstances>(this.apiPrefix + '/api/connect_instances', { headers });
            return data;
        } catch (error) {
            message.error('There was a problem retrieving Connect Instances');
            console.error('Failed to fetch connect instances:', error);
            return {};
        }
    }

    async getMetrics(
        instanceId: string,
        channel: string,
        queues: string,
        interval: number,
        metrics: string
    ): Promise<ConnectMetrics> {
        try {
            const body = { instanceId, channel, queues, interval, metrics };
            const headers = await this.getHeaders();
            const { data } = await axios.post<ConnectMetrics>(
                this.apiPrefix + '/api/connect_metrics',
                body,
                { headers }
            );
            return data;
        } catch (error) {
            message.error('There was a problem retrieving Connect Metrics');
            console.error('Failed to fetch metrics:', error);
            return {};
        }
    }

    async listQueues(instanceId: string): Promise<ConnectQueues> {
        try {
            const params = { instanceId };
            const headers = await this.getHeaders();
            const { data } = await axios.get<ConnectQueues>(
                this.apiPrefix + '/api/connect_queues',
                { headers, params }
            );

            const initialQueues = {
                '': {
                    name: 'All Queues',
                },
            };

            return Object.entries(data)
                .sort((a, b) => a[1].name.toLocaleLowerCase().localeCompare(b[1].name.toLocaleLowerCase()))
                .map(([queueId, queue]) => ({ [queueId]: queue }))
                .reduce((prev, curr) => Object.assign(prev, curr), initialQueues);
        } catch (error) {
            message.error('There was a problem retrieving Connect Queues');
            console.error('Failed to fetch queues:', error);
            return {};
        }
    }
}
