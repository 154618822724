import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    MetricsClient,
    ConnectChannels,
    ConnectInstances,
    ConnectQueues,
    OptionType
} from '../../util/MetricsClient';
import settings from '../../settings.json';
import Select, { StylesConfig } from 'react-select';

const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefix;

import { useContinuousAuth } from '../../pages/App';

const channels: ConnectChannels = {
    'VOICE': {
        name: 'Voice',
    },
    'CHAT': {
        name: 'Chat',
    },
};

const SelectorHeader = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 750px) {
        flex-direction: column;
    }

    & > div {
        flex: 1 1 1px;

        display: flex;
        flex-direction: column;

        padding: 10px;

        & button {
            width: 100%;
        }

        & > p {
            color: #6c757d;
            font-size: 14px;
            margin: -15px 0px 0px 0px;
        }
    }

    & .dropdown-menu {
        max-height: max(50vh, 200px);
        overflow-y: scroll;
    }
`;

const SelectorItem = styled.div`
    margin-right: 20px;
`;

type SelectorProps = PropsWithChildren<{
    instanceId: string;
    channel: string;
    queueId: string;
    setInstanceId: (instanceId: string) => void;
    setChannel: (channel: string) => void;
    setQueueId: (queue: string) => void;
    queues: ConnectQueues;
}>;

export function MetricsSelector(props: SelectorProps) {
    const {
        instanceId,
        channel,
        queueId,
        setInstanceId,
        setChannel,
        setQueueId,
        queues,
    } = props;

    const { getHeaders } = useContinuousAuth();
    const metricsClient = new MetricsClient(apiPrefix, getHeaders);
    const [instances, setInstances] = useState<ConnectInstances>({});

    // load instances
    useEffect(() => {
        metricsClient.listInstances().then(setInstances);
    }, []);

    // ensure instance ID is part of instances map
    useEffect(() => {
        if (Object.keys(instances).length > 0) {
            if (!instances[instanceId]) {
                setInstanceId(Object.keys(instances)[0]);
            }
        }
    }, [instances, instanceId]);

    // ensure queue ID is part of queues map
    useEffect(() => {
        if (Object.keys(queues).length > 0) {
            if (!queues[queueId]) {
                setQueueId(Object.keys(queues)[0]);
            }
        }
        // set QueueId to All Queues when no Queue is selected
        if (!queueId) {
            setQueueId("");
        }
    }, [queues, queueId]);

    // ensure channel is part of channels map
    useEffect(() => {
        if (Object.keys(channels).length > 0) {
            if (!channels[channel]) {
                setChannel(Object.keys(channels)[0]);
            }
        }
    }, [channels, channel]);

    // Custom styles for the Select component
    const customStyles: StylesConfig<OptionType, false> = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '300px',
            overflow: 'scroll',
        }),
        control: (provided) => ({
            ...provided,
            cursor: 'pointer',
        }),
    };

    type DropdownProps = {
        value: OptionType | null;
        handleChange: (selectedOption: OptionType | null) => void;
        options: OptionType[];
        formatOptionLabel?: (option: OptionType, meta: { context: string }) => React.ReactElement | null;
    };

    const Dropdown: React.FC<DropdownProps> = ({
       value,
       handleChange,
       options,
       formatOptionLabel,
    }) => (
        <Select
            value={value}
            onChange={handleChange}
            options={options}
            isSearchable
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
        />
    );


    const InstanceDropdown = () => {
        const instanceOptions: OptionType[] = Object.entries(instances).map(([instanceId, { name }]) => ({
            value: instanceId,
            label: name,
        }));

        const handleChange = (selectedOption: OptionType | null) => {
            if (selectedOption) {
                setInstanceId(selectedOption.value);
            }
        };

        const selectedValue: OptionType | null = instanceId
            ? {
                value: instanceId,
                label: instances[instanceId]?.name || '',
            }
            : null;

        return (
            <Dropdown
                value={selectedValue}
                handleChange={handleChange}
                options={instanceOptions}
            />
        );
    };

    const ChannelDropdown = () => {
        const channelOptions: OptionType[] = Object.entries(channels).map(([channelId, { name }]) => ({
            value: channelId,
            label: name,
        }));

        const handleChange = (selectedOption: OptionType | null) => {
            if (selectedOption) {
                setChannel(selectedOption.value);
            }
        };

        const selectedValue: OptionType | null = channel
            ? {
                value: channel,
                label: channels[channel]?.name || '',
            }
            : null;

        return (
            <Dropdown
                value={selectedValue}
                handleChange={handleChange}
                options={channelOptions}
            />
        );
    };

    const QueueDropdown = () => {
        const queueOptions: OptionType[] = Object.entries(queues).map(([queueId, { name }], index) => ({
            value: queueId,
            label: name,
            // isDisabled: index === 0,
        }));

        // Handling the divider, if necessary
        const formatOptionLabel = ({ label }: OptionType, { context }: { context: string }) => {
            if (context === 'menu' && label === queueOptions[0].label) {
                return (
                    <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                        {label}
                    </div>
                );
            }
            return <span>{label}</span>;
        };

        const handleChange = (selectedOption: OptionType | null) => {
            if (selectedOption) {
                setQueueId(selectedOption.value);
            }
        };

        const selectedValue: OptionType | null = queueId
            ? {
                value: queueId,
                label: queues[queueId]?.name || '',
            }
            : null;

        return (
            <Dropdown
                value={selectedValue}
                handleChange={handleChange}
                options={queueOptions}
                formatOptionLabel={formatOptionLabel}
            />
        );
    };

    return (
        <SelectorHeader>
            <SelectorItem>
                <p>Select Instance</p>
                <InstanceDropdown />
            </SelectorItem>
            <SelectorItem>
                <p>Select Channel</p>
                <ChannelDropdown />
            </SelectorItem>
            <div>
                <p>Select Queue</p>
                <QueueDropdown />
            </div>
        </SelectorHeader>
    );
}
