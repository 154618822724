import React, { useState } from 'react';
import styled from 'styled-components';

// Okta
import { login } from '../../pages/App';
import ringIconUrl from 'url:../../images/ring_logo_white.png';

const LandingWrapper = styled.div`
    margin: 8% 0px;
    width: 100%;
    max-width: 800px;
    background-color: #0A405A;
    border-radius: 10px;
    padding: 75px 50px;
    box-shadow: 1px 1px 4px rgba(0,0,0, .2);
    align-self: center;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-family: equipextralight;
        font-size: 32px;
    }

    & p {
        font-family: equipextralight;
        font-size: 20px;
    }

    @media (max-width: 820px) {
        width: 80%;
        padding: 25px 10px;
        text-align: center;

        & h1 {
            font-size: 20px;
        }

        & p {
            font-size: 14px;
        }
    }
`;

const ErrorIndicator = styled.div`
    color: #EF7D76;
    padding: 10px;
    font-size: 18px;

    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

const LogoImg = styled.img`
    align-self: center;
    margin: auto;
    width: 150px;
    padding: 15px;
    border-radius: 10px;

    @media (max-width: 500px) {
        width: 100px;
    }
`;

const LoginButton = styled.button`
    border: 1px solid #fff;
    border-radius: 5px;
    color: #0A405A;

    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        color: #1998d5;
    }

    &:active {
        background-color: #eff8fc;
        border-color: #eff8fc;
        color: #1998d5;
    }
`;

function OktaLogin() {
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async () => {
        try {
            await login();
            setErrorMessage(''); // Clear error message on successful login
        } catch (error) {
            setErrorMessage('You do not have access to this application. Please contact support.');
        }
    };

    return (
        <LandingWrapper>
            <LogoImg
                src={ringIconUrl}
                title='iconheader'
            />
            <h1>Welcome to CS Metrics!</h1>
            {errorMessage ?
                <ErrorIndicator>{errorMessage}</ErrorIndicator> :
                <>
                    <p>Please Login to access this tool</p>
                    <LoginButton
                        onClick={handleLogin}
                    >
                        Login
                    </LoginButton>
                </>
            }
        </LandingWrapper>
    );
}

export default OktaLogin;