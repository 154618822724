{
  "default": {
    "okta": {
      "cid": "0oar4augs10ZTT2bA1t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://metrics.cs-tools.dev.ring.com"
  },
  "metrics.cs-tools.dev.ring.com": {
    "okta": {
      "cid": "0oar4augs10ZTT2bA1t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://metrics.cs-tools.dev.ring.com"
  },
  "metrics.cs-tools.qa.ring.com": {
    "okta": {
      "cid": "0oar4augs10ZTT2bA1t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://metrics.cs-tools.qa.ring.com"
  },
  "metrics.cs-tools.ring.com": {
    "okta": {
      "cid": "0oargm1upihgm0wND1t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://metrics.cs-tools.ring.com"
  }
}
